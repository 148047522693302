import React from 'react'
import { Link } from 'gatsby'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import Styl from './index.module.css'

const Footer = ({ siteTitle }) => (
  <footer className={`${Styl.footer}`}>
    <div className="container">
      <div id="footer-info">
        {/* <div className="row mb-5">
          <div className="col-md-4 offset-md-2">
            <img
              src="https://res.cloudinary.com/brian-boals/image/upload/v1560994822/brianboals/Dinner_with_Dave_Ramsey.jpg"
              className="img-fluid mb-0"
              alt="Dinner with Dave Ramsey"
            />
          </div>
          <div className="col-md-4 align-self-center">
            <h3 className="text-white  mb-0 mt-xs-20">
              Brian loves Dave Ramsey's philosophies!
            </h3>
          </div>
        </div> */}
        <div className="row justify-content-between">
          <div className="col-md-6 col-xl-5 mb-5">
            <h4 className="text-white">Brian Boals</h4>
            <div className="footer-logo relative my-4">
              <Link to="/">
                <img
                  className="img-fluid"
                  src="/img/remax-logo-black.png"
                  alt="logo"
                />
              </Link>
            </div>
            <div className="d-flex flex-wrap align-items-center">
              <a
                href="https://www.facebook.com/BrianBoalsTeam/"
                target="_blank"
              >
                <svg
                  width="48"
                  height="49"
                  viewBox="0 0 48 49"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="48"
                    height="48"
                    transform="translate(0 0.348145)"
                    fill="#013DA5"
                  />
                  <path
                    d="M26.6667 26.348H30L31.3333 21.0146H26.6667V18.348C26.6667 16.9746 26.6667 15.6813 29.3333 15.6813H31.3333V11.2013C30.8987 11.144 29.2573 11.0146 27.524 11.0146C23.904 11.0146 21.3333 13.224 21.3333 17.2813V21.0146H17.3333V26.348H21.3333V37.6813H26.6667V26.348Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                href="https://twitter.com/i/flow/login?redirect_after_login=%2FBrianboalsremax"
                target="_blank"
                className="mx-3"
              >
                <svg
                  width="48"
                  height="49"
                  viewBox="0 0 1200 1227"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fIcon"
                >
                  <path
                    d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                href="https://www.youtube.com/watch?v=8N6NvrNvDVI&list=PLCj0epu0nwtvLkshOhFTo75CfohhyLW9V"
                target="_blank"
              >
                <svg
                  width="48"
                  height="49"
                  viewBox="0 0 48 49"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="48"
                    height="48"
                    transform="translate(0 0.348145)"
                    fill="#013DA5"
                  />
                  <path
                    d="M36.724 17.0123C37.3333 19.3883 37.3333 24.3483 37.3333 24.3483C37.3333 24.3483 37.3333 29.3083 36.724 31.6843C36.3853 32.9976 35.3947 34.031 34.14 34.3803C31.8613 35.015 24 35.015 24 35.015C24 35.015 16.1427 35.015 13.86 34.3803C12.6 34.0256 11.6107 32.9936 11.276 31.6843C10.6667 29.3083 10.6667 24.3483 10.6667 24.3483C10.6667 24.3483 10.6667 19.3883 11.276 17.0123C11.6147 15.699 12.6053 14.6656 13.86 14.3163C16.1427 13.6816 24 13.6816 24 13.6816C24 13.6816 31.8613 13.6816 34.14 14.3163C35.4 14.671 36.3893 15.703 36.724 17.0123ZM21.3333 29.015L29.3333 24.3483L21.3333 19.6816V29.015Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-xl-2 mb-5">
            <h6 className="text-white mb-5">Quick Links</h6>
            <ul className="list-unstyled footer-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-colorado-springs">About Colorado Springs</Link>
              </li>
              <li>
                <Link to="/testimonials">Testimonials</Link>
              </li>
              {/* <li>
                <Link to="">Listings</Link>
              </li> */}
              <li>
                <Link to="/the-buffini-difference">The Buffini Difference</Link>
              </li>
              <li>
                <Link to="/preferred-vendors">Preferred Vendors</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/news">Resources</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-xl-2 mb-5">
            <h6 className="text-white mb-5">Take Action Now</h6>
            <ul className="list-unstyled footer-menu">
              <li>
                <Link to="/property-listings/">Find a Home</Link>
              </li>
              <li>
                <Link to="/selling-process/">Sell</Link>
              </li>
              <li>
                <Link to="/finance/">Finance</Link>
              </li>
              <li>
                <Link to="/military/">Military</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-xl-3 mb-4">
            <h6 className="text-white mb-5">Connect with us</h6>
            <ul
              className={`list-unstyled text-white-body mt-4 ${
                Styl.contactInfo
              }`}
            >
              <li className="align-items-start d-flex">
                <LazyLoadImage
                  effect="blur"
                  alt=""
                  src="/img/f-phone.png"
                  width="18"
                />

                <a
                  href="tel:7194599955"
                  className="pt-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="mobile phone 2"
                >
                  (719) 459-9955
                </a>
              </li>
              <li className="align-items-start d-flex">
                <LazyLoadImage
                  effect="blur"
                  alt=""
                  src="/img/f-mail.png"
                  width="18"
                />

                <a
                  href="mailto:brianboals@gmail.com"
                  className="pt-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="email"
                >
                  brianboals@gmail.com
                </a>
              </li>
              <li className="align-items-start d-flex">
                <LazyLoadImage
                  effect="blur"
                  alt=""
                  src="/img/f-map.png"
                  width="18"
                />

                <span className="pt-1">
                  12265 Oracle Boulevard, Suite 200 - Colorado Springs, CO 80921
                </span>
              </li>
              {/* <li>
                <LazyLoadImage
                  effect="blur"
                  alt=""
                  src="/img/004-vintage-telephone-call.svg"
                  width="18"
                />
                <a
                  href="tel:719-459-9955"
                  className=""
                  target="_blank"
                  rel="noopener noreferrer"
                  title="telephone"
                >
                  (719) 459-9955
                </a>
              </li>
              <li>
                <LazyLoadImage
                  effect="blur"
                  alt=""
                  src="/img/004-vintage-telephone-call.svg"
                  width="18"
                />
                <a
                  href="tel:719-534-7922"
                  className=""
                  target="_blank"
                  rel="noopener noreferrer"
                  title="telephone 2"
                >
                  (719) 534-7922
                </a>
              </li> */}
              {/* <p className="font-weight-bold mt-4">Customer Care Line</p> */}
              {/* <li>
                <LazyLoadImage
                  effect="blur"
                  alt=""
                  src="/img/002-telephone.svg"
                  width="18"
                />
                <a
                  href="tel:719-534-7922"
                  className=""
                  target="_blank"
                  rel="noopener noreferrer"
                  title="mobile phone"
                >
                  (719) 534-7922
                </a>
              </li> */}
            </ul>
            <ul className="list-unstyled footer-social">
              <li style={{ backgroundColor: '#3B5998' }}>
                <a
                  href="https://www.facebook.com/BrianBoalsTeam/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage
                    effect="blur"
                    src="/img/002-facebook.svg"
                    alt="facebook"
                    width="40"
                    height="40"
                  />
                </a>
              </li>
              <li style={{ backgroundColor: '#55ACEE' }}>
                <a
                  href="https://twitter.com/Brianboalsremax"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    width="40"
                    height="34"
                    viewBox="0 0 1200 1227"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="p-2"
                  >
                    <path
                      d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </li>
              <li style={{ backgroundColor: '#D42428' }}>
                <a
                  href="https://www.youtube.com/watch?v=8N6NvrNvDVI&amp;list=PLCj0epu0nwtvLkshOhFTo75CfohhyLW9V"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage
                    effect="blur"
                    src="/img/001-youtube.svg"
                    alt="youtube"
                    width="40"
                    height="40"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="copywrite" className="mt-5 text-center">
        <p className="mb-0 text-white-body" style={{ fontSize: '0.8rem' }}>
          © Copyright {new Date().getFullYear()} Brian Boals. All rights
          reserved. Designed and powered by{' '}
          <a
            title="Footer link"
            href="http://webriq.com"
            target="_blank"
            alt="WebriQ"
            rel="nofollow noreferrer noopener"
            style={{ fontSize: '0.8rem', textDecoration: 'underline' }}
          >
            WebriQ
          </a>
          .
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
